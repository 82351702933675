import { FC } from 'react'

const BatteryIcon: FC<{ className?: string }> = ({ className = '' }) => (
	<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 112 52">
		<path
			d="M20.411 51.12h59.766c3.375 0 6.367-.268 8.976-.805 2.61-.537 4.837-1.734 6.682-3.59 1.855-1.865 3.043-4.095 3.564-6.688.52-2.594.781-5.572.781-8.936V20.456c0-3.363-.26-6.342-.781-8.935-.52-2.593-1.709-4.818-3.564-6.673-1.856-1.867-4.086-3.071-6.69-3.614-2.604-.542-5.593-.813-8.968-.813H20.25c-3.266 0-6.199.274-8.798.822-2.598.548-4.83 1.755-6.697 3.62C2.909 6.73 1.718 8.953 1.18 11.53c-.537 2.577-.805 5.499-.805 8.765V31.1c0 3.364.265 6.342.797 8.936.532 2.593 1.72 4.823 3.565 6.689 1.877 1.855 4.117 3.052 6.722 3.589 2.604.537 5.588.805 8.951.805Zm-1.041-5.403c-2.116 0-4.132-.206-6.047-.619-1.915-.412-3.453-1.199-4.614-2.36-1.15-1.172-1.923-2.699-2.32-4.581-.395-1.883-.594-3.888-.594-6.014v-12.55c0-2.18.199-4.228.595-6.143.396-1.916 1.163-3.465 2.303-4.647 1.16-1.172 2.712-1.953 4.655-2.344 1.942-.39 4.009-.586 6.2-.586h61.67c2.117 0 4.13.2 6.04.602 1.909.402 3.439 1.183 4.589 2.344 1.161 1.183 1.94 2.718 2.336 4.606.396 1.888.594 3.896.594 6.022v12.696c0 2.126-.201 4.131-.603 6.014-.401 1.882-1.177 3.41-2.327 4.581-1.15 1.172-2.68 1.962-4.59 2.368-1.91.407-3.922.61-6.038.61h-61.85Zm85.661-10.335c.976-.055 1.996-.489 3.059-1.303 1.064-.813 1.965-1.928 2.702-3.344.738-1.416 1.107-3.068 1.107-4.956 0-1.888-.369-3.54-1.107-4.956-.737-1.416-1.638-2.531-2.702-3.345-1.063-.814-2.083-1.248-3.059-1.302v19.206Zm-87.338 6.12H62.73c2.94 0 4.953-.549 6.039-1.645 1.106-1.106 1.66-3.114 1.66-6.022v-16.13c0-1.507-.128-2.739-.383-3.694-.255-.955-.675-1.73-1.261-2.327-1.118-1.075-3.136-1.612-6.055-1.612H17.84c-1.605 0-2.894.122-3.865.367-.97.244-1.75.659-2.335 1.245-.586.596-1.007 1.383-1.262 2.36-.255.976-.382 2.262-.382 3.857v15.934c0 1.52.127 2.756.382 3.711.255.955.676 1.725 1.262 2.311 1.117 1.096 3.135 1.644 6.054 1.644Z"
			fill="currentColor"
			fillRule="nonzero"
		/>
	</svg>
)

export default BatteryIcon
