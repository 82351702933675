import { FC } from 'react'

const WifiIcon: FC<{ className?: string }> = ({ className = '' }) => (
	<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 65">
		<path
			d="M44.842 64.826c.456 0 .911-.13 1.367-.39.456-.26 1.074-.765 1.856-1.514l9.423-9.033c.293-.293.472-.651.537-1.074.066-.424-.048-.814-.341-1.172-1.302-1.66-3.076-3.11-5.323-4.346-2.246-1.237-4.752-1.856-7.519-1.856-2.832 0-5.387.651-7.666 1.954-2.279 1.302-4.053 2.815-5.322 4.54a1.208 1.208 0 0 0-.171.977c.081.358.285.684.61.977l9.326 9.033c.782.716 1.4 1.212 1.856 1.49.456.276.911.414 1.367.414ZM22.43 43.976c.423.456.887.66 1.391.61.505-.048.969-.3 1.392-.756 2.246-2.539 5.119-4.639 8.618-6.299 3.5-1.66 7.17-2.474 11.011-2.441a24.514 24.514 0 0 1 11.108 2.515c3.5 1.709 6.405 3.833 8.716 6.372.39.423.822.634 1.294.634.472 0 .92-.228 1.343-.683l6.103-6.006c.326-.326.497-.7.513-1.123a1.825 1.825 0 0 0-.366-1.172c-1.953-2.376-4.395-4.508-7.324-6.396-2.93-1.889-6.21-3.386-9.84-4.493-3.629-1.106-7.478-1.66-11.547-1.66-4.036 0-7.87.554-11.5 1.66-3.629 1.107-6.908 2.604-9.838 4.493-2.93 1.888-5.371 4.02-7.324 6.396-.293.358-.431.74-.415 1.147.016.407.203.79.561 1.148l6.104 6.055ZM6.56 28.01c.359.423.798.626 1.32.61.52-.016.976-.236 1.366-.66 4.688-5.012 10.067-8.804 16.138-11.376 6.07-2.572 12.557-3.857 19.458-3.857 6.966 0 13.485 1.302 19.556 3.906 6.07 2.604 11.466 6.396 16.186 11.377.358.358.79.537 1.294.537.505 0 .936-.212 1.294-.635l5.469-5.42c.325-.358.496-.74.512-1.147.017-.407-.122-.79-.415-1.148-2.994-3.71-6.779-7.031-11.352-9.96-4.574-2.93-9.644-5.242-15.21-6.934C56.609 1.61 50.832.763 44.842.763s-11.768.847-17.334 2.54c-5.566 1.692-10.636 4.004-15.21 6.933-4.574 2.93-8.358 6.25-11.353 9.961-.26.358-.39.74-.39 1.148 0 .407.179.79.537 1.147L6.56 28.01Z"
			fill="currentColor"
			fillRule="nonzero"
		/>
	</svg>
)

export default WifiIcon
